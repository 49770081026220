
import "./Contact.scss"
import React, {useState} from "react";
import sendIcon from "../../assets/images/sending-icon-fi.svg"




export default function Contact(){

    const [activeComponent, setActive] = useState(0)

    function ContactBtn(props){
        return(
            <button 
            className={activeComponent === props.val ? "active contact-btn" : "contact-btn"}
            onClick={()=>{
                setActive(props.val)
            }}
            >
                {props.name}
            </button>
        )
    }
    

    return(
        <div className="contact-wrapper">
            <div className="contact">
                <div className="contact-titles">
                    <ContactBtn val={0} name="Téléphone"></ContactBtn>
                    <ContactBtn val={1} name="Email"></ContactBtn>
                    <ContactBtn val={2} name="Adresse"></ContactBtn>
                    <ContactBtn val={3} name="Horaires"></ContactBtn>
                </div>

                <div className="contact-content">
                    <p className={activeComponent === 0 ? "" : "pinvisible"}>05 62 72 19 99</p>
                    <div className={activeComponent === 1 ? "" : "pinvisible"}>
                        <p>contact@idealpieces.fr</p>
                        <a href="mailto:contact@idealpieces.fr">
                            <button className="sending-btn">
                                <p className="sending-btn--text">Contactez-nous</p>
                                <img src={sendIcon} alt="envoyer un email à IDÉAL PIÈCES TOULOUSE" />
                            </button>
                        </a>
                    </div>
                    <p className={activeComponent === 2 ? "" : "pinvisible"}>109B rue de Negreneys, 31200 Toulouse</p>
                    <p className={activeComponent === 3 ? "contact-horaires" : "pinvisible"}>
                        <div>
                            Lundi à Vendredi :<br></br>
                            <span>08:00 - 12:00, 14:00 - 18:00</span><br></br><br></br>
                        </div>
                        <div>
                            Samedi et Dimanche :<br></br>
                            <span>Fermé</span>
                        </div>
                    </p>
                </div>
            </div>
        </div>
    )
}
