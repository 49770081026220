import React, {useState, useEffect} from "react";
import {Link as AnimationLink} from "react-scroll";
import {Link} from "react-router-dom";
import "./Accueil.scss"
import Footer from "../../components/Footer/Footer"
import Contact from "../../components/Contact/Contact";
import mobileMainLogo from "../../assets/images/logo-accueil-mobile.svg"
import arrowDown from "../../assets/images/arrow-down.svg"
import desktopMainLogo from "../../assets/images/logo-accueil-desktop.svg"


export default function Accueil(){

    const bodyEl = document.getElementById("body-el")
    const [display, setDisplay] = useState(false)
    const [height, setHeight] = useState(window.innerHeight + "px")




    useEffect(()=>{

        function handleResize(){
            setHeight(window.innerHeight + 'px')
        }
        window.addEventListener("resize",handleResize)

    }, [])

    const divStyle = {
        height: height
    }

    function navigation(){
        setDisplay(!display)
        if (!display) {
            bodyEl.classList.add("stop-scrolling")
        } else {
            bodyEl.classList.remove("stop-scrolling")
        }
    }

    return(
        <>
        <div className="accueil">
            <section className="accueil-mobile">
                <div className="accueil-first-wrapper" style={divStyle}>
                    <nav className="mobile-navbar">
                        <label className="hamburger-btn">
                            <input type="checkbox"
                            onChange={navigation}
                            name=""
                            id=""
                            />
                            <div className={display ? "menu-btn isOpen" : "menu-btn"}>
                                <div className="menu-btn--fst-part"></div>
                                <div className="menu-btn--snd-part"></div>
                                <div className="menu-btn--trd-part"></div>
                            </div>
                        </label>
                        <Link to='/devis' className="btn">Demander un devis</Link>
                    </nav>
                    <div className="logo-wrapper"><img src={mobileMainLogo} alt="logo de Idéal Pièces Toulouse" /></div>
                        <div className="learn-more">
                            <AnimationLink
                                to="pres"
                                smooth={true}
                                offset={-20}
                                duration={500}
                            >
                                EN SAVOIR PLUS
                            </AnimationLink>
                            <img src={arrowDown} alt="Faites défiler pour en savoir plus sur Idéal Pièces toulouse" />
                        </div>
                </div>
                <div className={display ? "navigation-menu offset" : "navigation-menu"} id="navigation-menu-id">

                    <Link to='/produits' onClick={navigation}>NOS PRODUITS</Link>

                    <Link to='/' onClick={navigation} className="available-soon">ACTUALITÉ</Link>
                    <Link to='/' onClick={navigation} className="available-soon">DESTOCKAGE</Link>
                    <Link to='/devis' onClick={navigation} className="btn">DEMANDER UN DEVIS</Link>
                    <div className="navigation-menu--bottom">
                        <a onClick={navigation} href="#contact-area">Nous contacter</a>
                        <p></p>
                    </div>
                </div>
                <div className="presentation-entreprise" name="pres">
                    <p>
                        La société <span>IDÉAL PIÈCES</span>, basée à Toulouse, est spécialisée dans la vente de pièces détachées automobiles neuves toutes marques.<br/><br/>
                        Depuis plus de 20 ans <span>IDÉAL PIÈCES</span> met au service des clients particuliers et professionnels, son expérience et son savoir-faire de la pièce automobile multimarques.<br/><br/>
                        L’atout du magasin <span>IDÉAL PIÈCES</span> est un réapprovisionnement rapide grâce à de nombreux partenaires, et sa force est de proposer des pièces de qualité Prémium et origine, tout comme une gamme de tarif inférieure.<br/><br/>
                        L’équipe d'<span>IDÉAL PIÈCES</span> vous accueille avec un grand sens du service et du conseil, et une excellente connaissance afin de trouver des pièces courantes comme des pièces plus rares. Sérieux, proximité et réactivité sont ses points forts.<br/><br/>
                        N'hésitez pas à venir nous voir en magasin, nous nous ferons un plaisir de vous conseiller sur les meilleures pièces à monter sur votre véhicule.
                    </p>
                </div>
                <div id="contact-area">
                    <Contact></Contact>
                </div>
            </section>

            <section className="accueil-desktop" >
                <nav className="desktop-navbar">
                <Link to='/produits' className="link">Nos Produits</Link>
                    <Link to='/' className="link available-soon">Nos Actualités</Link>
                    <Link to='/' className="link available-soon">Destockage</Link>
                    <Link to='/devis' className="btn">Demander un devis</Link>
                </nav>
                
                <div className="accueil-desktop--main-content">
                    <div className="main-content">
                        <img src={desktopMainLogo} alt="logo de Idéal Pièces Toulouse" />
                            <p>
                            La société <span>IDÉAL PIÈCES</span>, basée à Toulouse, est spécialisée dans la vente de pièces détachées automobiles neuves toutes marques.<br/><br/>
                            Depuis plus de 20 ans <span>IDÉAL PIÈCES</span> met au service des clients particuliers et professionnels, son expérience et son savoir-faire de la pièce automobile multimarques.<br/><br/>
                            L’atout du magasin <span>IDÉAL PIÈCES</span> est un réapprovisionnement rapide grâce à de nombreux partenaires, et sa force est de proposer des pièces de qualité Prémium et origine, tout comme une gamme de tarif inférieure.<br/><br/>
                            L’équipe d'<span>IDÉAL PIÈCES</span> vous accueille avec un grand sens du service et du conseil, et une excellente connaissance afin de trouver des pièces courantes comme des pièces plus rares. Sérieux, proximité et réactivité sont ses points forts.<br/><br/>
                            N'hésitez pas à venir nous voir en magasin, nous nous ferons un plaisir de vous conseiller sur les meilleures pièces à monter sur votre véhicule.
                            </p>
                    </div>
                    <div className="accueil-desktop--contact">
                        <Contact></Contact>
                    </div>
                </div>
            </section>
            <Footer></Footer>

        </div>
        </>
    )
}