import "./Devis.scss"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import ContactForm from "../../components/ContactForm/ContactForm"
import Separator from "../../components/Separator/Separator"
import React from 'react'
import accessPlan from "../../assets/images/access-plan.svg"
import { GoogleMap, useLoadScript, Marker} from "@react-google-maps/api"

export default function Devis() {


  // Début du code pour pouvoir intégré une map de google à la page

  const containerStyle = {
    zIndex:"0",
    borderRadius: "30px",
    width: '90vw',
    maxWidth: '500px',
    height: '45vw',
    maxHeight: '250px',
  };

  const center = {
    lat: 43.62329794324608,
    lng: 1.442363861744679
  }; 

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';


  // Fin du code pour pouvoir intégré une map de google à la page + voir balise GoogleMap


  return (
      <>
      <Header title = "Demander un devis"></Header>
      <div className="devis">
        <div className="devis-wrapper">

          <ContactForm></ContactForm>


          <Separator></Separator>

          <section className="localisation">
            <div className="map-wrapper">
              <div className="map-border">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={14}
                >
                  <Marker position={center}></Marker>
                </GoogleMap>
              </div>
              <a className="plan-acces" target="blank" href="https://www.google.com/maps/place/Id%C3%A9al+Pi%C3%A8ces/@43.6232921,1.4398108,17z/data=!4m5!3m4!1s0x12aebb560ca97c3f:0x6bca9b536fa39dbc!8m2!3d43.6233419!4d1.4423495">
                <img src={accessPlan} alt="lien vers le plan d'accès sur google maps" />
                PLAN D'ACCÈS
              </a>
            </div>
            <p>
              109Bis Rue de Negreneys, 31200 Toulouse
            </p>
          </section>

          {/* <Separator></Separator> */}

        </div>
      </div>
      <Footer></Footer>
      </>
  )
}

