import './App.scss';
import Accueil from "./containers/Accueil/Accueil"
import Produits from "./containers/Produits/Produits"
import Devis from './containers/Devis/Devis';
import {Routes, Route} from "react-router-dom"
import Mentions from './containers/Mentions_Legales/Mentions';

function App() {
  return (
    <div className="App">
      
      {/* <Accueil></Accueil> */}

      <Routes>
        {/* <Route path='/ideal-piece-app' element={<Accueil></Accueil>}/> */}
        <Route path='/' element={<Accueil></Accueil>}/>
        <Route path='/produits' element={<Produits></Produits>}/>
        <Route path='/devis' element={<Devis></Devis>}/>
        <Route path='/mentions-legales' element={<Mentions></Mentions>}/>
      </Routes>
    </div>
  );
}

export default App;

/*
REVOIR LES ROUTES pour enlever 
le ideal-piece-app et laisser juste la racine
*/


/* <a
  className="App-link"
  href="https://reactjs.org"
  target="_blank"
  rel="noopener noreferrer"
>
  Learn React
</a> */