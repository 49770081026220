
import Carousel from "react-elastic-carousel"
import "./BrandSlider.scss"

import bosch from "../../assets/marques/bosch.svg"
import continental from "../../assets/marques/continental.svg"
import delphi from "../../assets/marques/delphi.svg"
import monroe from "../../assets/marques/monroe.svg"
import ngk from "../../assets/marques/ngk.svg"
import valeo from "../../assets/marques/valeo.svg"
import brembo from "../../assets/marques/brembo.svg"
import dayco from "../../assets/marques/dayco.svg"
import gates from "../../assets/marques/gates.svg"
import moog from "../../assets/marques/moog.svg"
import sachs from "../../assets/marques/sachs.svg"
import trw from "../../assets/marques/trw.svg"



export default function BrandSlider() { // Carousel avec les marques partenaires


    const breakPoints = [
        {width: 1, itemsToShow: 1},
        {width: 350, itemsToShow: 2},
        {width: 500, itemsToShow: 3},
        {width: 700, itemsToShow: 4},
    ]

    const imgStyle = {
        padding: "5px",
        width: "170px",
        height: "100px"
    }


    return(
        <div className="brandslider" >
            {/* <h1>bonjour</h1> */}
            <Carousel breakPoints={breakPoints}>
                <img src={bosch} alt="" style={imgStyle}/>
                <img src={continental} alt="" style={imgStyle}/>
                <img src={delphi} alt="" style={imgStyle}/>
                <img src={brembo} alt="" style={imgStyle}/>
                <img src={dayco} alt="" style={imgStyle}/>
                <img src={gates} alt="" style={imgStyle}/>
                <img src={monroe} alt="" style={imgStyle}/>
                <img src={ngk} alt="" style={imgStyle}/>
                <img src={sachs} alt="" style={imgStyle}/>
                <img src={trw} alt="" style={imgStyle}/>
                <img src={valeo} alt="" style={imgStyle}/>
                <img src={moog} alt="" style={imgStyle}/>
            </Carousel>
        
        </div>
    )
}