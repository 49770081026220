


export default function Separator(){

    const sepWrapperStyle ={
        position:"relative",
        width:"100%",

        padding:"20px",
        display:"flex",
        alignItems : "center",
        justifyContent:"space-evenly",
    }

    const sepStyle ={
        width:"100%",
        border: ".5px solid rgba(247, 247, 247,0.5)",
    }

    const ipStyle = {
        paddingLeft:"20px",
        paddingRight:"20px",
        whiteSpace: "nowrap",
        fontWeight:"600",
        width:"fit-content",
        color:"rgba(247, 247, 247,0.5)"
        
    }
    
    return(
        <div style={sepWrapperStyle}>
            <span style={sepStyle}></span>
            <p style={ipStyle}>IDÉAL PIÈCES</p>
            <span style={sepStyle}></span>
        </div>
    )
}