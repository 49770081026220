import "./Produits.scss"
import Header  from "../../components/Header/Header"
import Separator from "../../components/Separator/Separator"
import BrandSlider from "../../components/BrandSlider/BrandSlider"
import Footer from "../../components/Footer/Footer"
import CategoryCard from "../../components/CategoryCard/CategoryCard"

import huiles from "../../assets/photos/huiles.jpg"
import outils from "../../assets/photos/outils.jpg"
import additifs from "../../assets/photos/additifs.jpg"
import batteries from "../../assets/photos/batteries.jpg"
import consommables from "../../assets/photos/consomable.jpg"

export default function Produits(){
    return(
        <>
        <Header title="NOS PRODUITS"></Header>
        <div className="produits">
            <div className="produits-wrapper">
                {/* <h2 className="produits-titre-section">Pièces Auto</h2> */}
                <section className="listes-produits">
                    <figure>
                        <figcaption>Train roulant</figcaption>
                        <ul className="p-liste">
                            <li>Freinage</li>
                            <li>Liaison au sol</li>
                            <li>Transmissions</li>
                            <li>Suspensions</li>
                            <li>Stabilité</li>
                            <li>Direction</li>
                        </ul>
                    </figure>
                    <figure className="large-figure">
                        <figcaption>Environnement moteur</figcaption>
                        <ul className="p-liste">
                            <li>Distribution et accessoires</li>
                            <li>Échappements et attelages</li>
                            <li>Embrayage</li>
                            <li>Filtration</li>
                        </ul>
                    </figure>
                    <figure>
                        <figcaption>Carrosserie</figcaption>
                        <ul className="p-liste">
                            <li>Optiques</li>
                            <li>Pare-chocs</li>
                            <li>Pare-boue</li>
                            <li>Capots</li>
                            <li>Faces avant</li>
                            <li>Ailes avant</li>
                            <li>Rétroviseurs</li>
                        </ul>
                    </figure>
                    <figure className="large-figure">
                        <figcaption>Électricité</figcaption>
                        <ul className="p-liste">
                            <li>Alternateurs</li>
                            <li>Démarreurs</li>
                            <li>Lampes</li>
                            <li>Pièces détachées</li>
                        </ul>
                    </figure>
                </section>
                <Separator/>
                {/* <h2 className="produits-titre-section">Produits en magasin</h2> */}
                <section className="categoryCardsWrapper">
                    <CategoryCard 
                    titre = "Lubrifiants"
                    desc = "Retrouvez notre sélection de lubrifiants et ajoutez les à votre panier pour les réserver et venir les chercher en magasin."
                    photo = {huiles}
                    ></CategoryCard>
                    <CategoryCard
                    titre = "Additifs"
                    desc = "Retrouvez notre sélection d'additifs et ajoutez les à votre panier pour les réserver et venir les chercher en magasin."
                    photo = {additifs}
                    ></CategoryCard>
                    <CategoryCard
                    titre = "Batteries"
                    desc = "Retrouvez notre sélection de batteries et ajoutez les à votre panier pour les réserver et venir les chercher en magasin."
                    photo = {batteries}
                    ></CategoryCard>
                    <CategoryCard
                    titre = "Consommables"
                    desc = "Retrouvez nos différents consommables et ajoutez les à votre panier pour les réserver et venir les chercher en magasin."
                    photo = {consommables}
                    ></CategoryCard>
                    <CategoryCard
                    titre = "Outillage"
                    desc = "Prochainement dans votre magasin..."
                    photo = {outils}
                    ></CategoryCard>
                </section>

                <Separator/>
                <h2 className="produits-titre-section">Nos marques partenaires</h2>
                <BrandSlider></BrandSlider>
            </div>
        </div>
        <Footer></Footer>
        </>
    )
}