
import "./Header.scss"
import {Link} from "react-router-dom";
import arrow from "../../assets/images/arrow-down.svg"

export default function Header(props){

    return(
        <div className="header">
            <div className="header-wrapper">
                <Link className="to-home" to='/'>
                    <img src={arrow} alt="" />
                </Link>
                <h1>{props.title}</h1>
            </div>
        </div>
    )

}