import "./CategoryCard.scss"
import React from 'react'

export default function CategoryCard(props) {

  const imgStyle = {
    width: "100%",
    height: "50%",
    borderRadius: "30px 30px 0px 0px",
    backgroundColor: "rgba(247, 247, 247,0.5)",
    backgroundImage: `url(${props.photo})`,
    backgroundPosition: "center",
    backgroundSize: "cover"
  }
  return (
    <div className="categoryCard">
      <div className="img-section" style={imgStyle}></div>
      <div className="desc-section">
        <h1>{props.titre}</h1>
        <p>{props.desc}</p>
      </div>
    </div>
  )
}
