import "./ContactForm.scss"
import sendingIcon from "../../assets/images/sending-icon-fi.svg"
import React, {useState, useRef} from 'react'
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';

import validIcon from "../../assets/images/valid-icon.svg"
import errorIcon from "../../assets/images/error-icon.svg"

export default function ContactForm() {

    const [displayValid, setDisplayValid] = useState(false)
    const [displayError, setDisplayError] = useState(false)

    const inputsToClear = document.getElementsByClassName("clear-on-submit")

    const form = useRef();

    const {register, handleSubmit} = useForm()

    const sendEmail = () => {
        // e.preventDefault();
        emailjs.sendForm('service_test', 'template_f8gfl6h', form.current, 'BRBWPxLUdiIelE3T3')
        .then((result) => {
        setDisplayValid(true)
        setDisplayError(false)
        console.log(result.text)
        }, (error) => {
        setDisplayError(true)
        setDisplayValid(false)
        console.log(error.text)
        })

        for (let i = 0; i<inputsToClear.length; i++){
            inputsToClear[i].value = ""
        }
    }

    const onSubmit = data => {
        console.log(data)
        sendEmail()
    }

    

  return (
    <div className="cf-wrapper">
        <form className="text-group-wrapper" ref={form} onSubmit={handleSubmit(onSubmit)} action="">
            <section className="smaller-text-group">
                <section className="text-group">
                    {/* NOM, PRÉNOM ET EMAIL */}
                    <div className="textField">
                        <input className="clear-on-submit" type="text-area" {...register("nom", {required: "Ce champ est obligatoire"})} id="nom" placeholder="DUPONT"/>
                        
                        <label htmlFor="nom">Nom*</label>
                    </div>
                    <div className="textField">
                        <input className="clear-on-submit" type="text-area" {...register("prenom", {required: "Ce champ est obligatoire"})} id="prenom" placeholder="Jean"/>
                        <label htmlFor="prenom">Prénom*</label>
                    </div>
                    <div className="textField">
                        <input className="clear-on-submit" type="text-area" {...register("email", {required: "Ce champ est obligatoire"})} id="email" placeholder="jean.dupont@gmail.com"/>
                        <label htmlFor="email">Email*</label>
                    </div>
                </section>
                <section className="text-group">
                    {/* TEL, IMMATRICULATION ET OBJET */}
                    <div className="textField">
                        <input className="clear-on-submit" type="text-area" {...register("tel", {required: "Ce champ est obligatoire"})} id="tel" placeholder="06 77 88 99 10"/>
                        <label htmlFor="tel">Téléphone*</label>
                    </div>
                    <div className="textField">
                        <input className="clear-on-submit" type="text-area" {...register("immatriculation", {required: "Ce champ est obligatoire"})} id="immatriculation" placeholder="AB-123-CD"/>
                        <label htmlFor="immatriculation">Numéro d'immatriculation*</label>
                    </div>
                    <div className="textField">
                        <input className="clear-on-submit" type="text-area" {...register("objet", {required: "Ce champ est obligatoire"})} id="objet" placeholder="Objet de votre message"/>
                        <label htmlFor="objet">Objet*</label>
                    </div>
                </section>
            </section>

            
            {/* MESSAGE */}
            <div className="textField">
                <textarea className="clear-on-submit" {...register("message", {required: "Ce champ est obligatoire"})} id="message" cols="30" rows="10"
                placeholder="Exprimez votre besoin ici... Préciser cas particulier (voiture importée, moteur changé...)"></textarea>
                <label htmlFor="message">Message*</label>
                <button type="submit" className="submit-btn">
                    <img src={sendingIcon} alt="Envoyer votre devis à Idéal Pièces" />
                </button>
            </div>
        </form>
        <div className="post-submit-message">
            <div className={displayValid ? "valid-message" : "valid-message hide"}>
                <p>
                Votre message à bien été envoyé !  Nous vous recontacterons.
                </p>
                <img src={validIcon} alt="" />
            </div>
            <div className={displayError ? "error-message" : "error-message hide"}>
                <p>
                Votre message n'a pas été envoyé ! Veuillez réessayer.
                </p>
                <img src={errorIcon} alt="" />
            </div>
        </div>
    </div>
  )
}
