import "./Mentions.scss"
import React from 'react'
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

export default function Mentions() {

  return (
    <>
        <Header title="MENTIONS LÉGALES"></Header>
        <div className="mentions-wrapper">
          <p>
            <span>Nom de la société :</span> IDÉAL PIÈCES TOULOUSE<br />
            <span>Numéro de Siret :</span> 51793325500013<br />
            <span>Nom du responsable :</span> Stève ABATUCI<br /><br />

            <span>Adresse de la société :</span> 109B rue de Negreneys, 31200 Toulouse<br />
            <span>Adresse du siège social de la société :</span> 109B rue de Negreneys, 31200 Toulouse<br /><br />

            <span>Éditeur de la publication :</span> IDÉAL PIÈCES TOULOUSE<br />
            <span>Directeur de la publication :</span> IDÉAL PIÈCES TOULOUSE<br />
            <span>Responsable de la rédaction :</span> IDÉAL PIÈCES TOULOUSE<br />
            <span>Hébergeur :</span> IONOS
          </p>
        </div>
        <Footer></Footer>
    </>
  )
}
